
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import image6 from "./image6.jpg";
import image7 from "./image7.jpg";
import image9 from "./image9.jpg";
import image10 from "./image10.jpg";
import image11 from "./image11.jpg";
import image12 from "./image12.jpg";

export const images = [
  {
    original: image11,
    thumbnail: image11,
  },
  {
    original: image10,
    thumbnail: image10,
  },
  {
    original: image12,
    thumbnail: image12,
  },
  {
    original: image7,
    thumbnail: image7,
  },
  {
    original: image2,
    thumbnail: image2,
  },
  {
    original: image3,
    thumbnail: image3,
  },
  {
    original: image4,
    thumbnail: image4,
  },
  {
    original: image5,
    thumbnail: image5,
  },
  {
    original: image6,
    thumbnail: image6,
  },
  {
    original: image9,
    thumbnail: image9,
  },
];
